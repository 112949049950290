import React from 'react';
import Layout from '../components/layout';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'gatsby';

export default () => {
  return (
    <Layout title={`Page Not Found`}>
      <Container>
        <Row className='mt-5'>
          <Col className='mt-5'>

            <div>
              <h1>Privacy Policy</h1>
              <p>Your privacy is important to us. Our policy to respect your privacy regarding any information we may collect from you on our website <a href='https://sensitivityconverter.com'>sensitivityconverter.com</a>, and other sites we own and operate.</p>
              <h3>Cookies</h3>
              <p>We use "cookies" to collect information about you and your activity on our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.</p>
              <h3>Third-party access to information</h3>
              <p>We use third-party services for:</p>
              <ul>
                <li>Analytics tracking</li>
                <li>Advertising and promotion</li>
              </ul>
              <p>These services may access our data solely for the purpose of performing specific tasks on our behalf. We do not share any personally identifying information with them without your explicit consent. We do not give them permission to disclose or use any of our data for any other purpose.</p>
              <p>We may, from time to time, allow limited access to our data by external consultants and agencies for the purpose of analysis and service improvement. This access is only permitted for as long as necessary to perform a specific function. We only work with external agencies whose privacy policies align with ours.</p>
              <p>We will refuse government and law enforcement requests for data if we believe a request is too broad or unrelated to its stated purpose. However, we may cooperate if we believe the requested information is necessary and appropriate to comply with legal process, to protect our own rights and property, to protect the safety of the public and any person, to prevent a crime, or to prevent what we reasonably believe to be illegal, legally actionable, or unethical activity.</p>
              <p>We do not otherwise share or supply personal information to third parties. We do not sell or rent your personal information to marketers or third parties.</p>
              <h3>Limits of our policy</h3>
              <p>This privacy policy only covers SensitivityConverter's own collecting and handling of data. We only work with partners, affiliates and third-party providers whose privacy policies align with ours, however, we cannot accept responsibility or liability for their respective privacy practices.</p>
              <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
              <h3>Changes to this policy</h3>
              <p>At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website.</p>
              <h2>Cookie Policy</h2>
              <p>We use cookies to help improve your experience of <a href="https://sensitivityconverter.com">sensitivityconverter.com</a>. This cookie policy is part of our privacy policy and covers the use of cookies between your device and our site. We also provide basic information on third-party services we may use, who may also use cookies as part of their service, though they are not covered by our policy.</p>
              <p>If you don't wish to accept cookies from us, you should instruct your browser to refuse cookies from <a href="https://sensitivityconverter.com">sensitivityconverter.com</a>, with the understanding that we may be unable to provide you with some of your desired content and services.</p>
            </div>

            <Link to='/'>Back to home</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
